@import 'src/variables';

.reportView {
  min-width: 900px !important;
  max-width: 900px !important;
  margin: 0 auto;
}

.pdfButton {
  height: 28px;
  display: flex;
  gap: 6px;
  padding: 4px 8px;
  color: $dark-font-color;
  font-weight: 500;
  background-color: white;
  border-radius: 6px;
  box-shadow: $box-shadow-with-border;
  transition: 0.2s box-shadow;

  span {
    color: $dark-font-color
  }
}
