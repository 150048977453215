@import 'src/variables';

.card {
    position: absolute;
    background-color: white;
    padding: 14px;
    top: 14px;
    left: 240px;
    display: flex;
    flex-direction: column;
    transition: visibility 0.1s, opacity 0.25s, left 0.25s;
    height: fit-content;
    border-radius: 8px;
    gap: 10px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    max-width: 280px;

    &.shown {
        visibility: visible;
        opacity: 1;
        left: 288px;
        transition: visibility 0.4s, opacity 0.25s, left 0.25s;
    }

    .backButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px;
        background-color: #eee;
        border-radius: 6px;
        &:hover {
            cursor: pointer;
        }
    }

    .updateMotivesButton {
        background-color: #000;
        color: white;
        font-weight: 500;
        width: fit-content;
        padding: 0 10px;
        height: 28px;
        border-radius: 4px;
    }

    .commentsInput {
        display: flex;
        position: relative;
        font-size: 13px;
        width: 240px;
        align-items: flex-end;

        .input {
            width: 200px;
            max-height: 100px;
            background-color: #f4f4f4;
            padding: 4px 8px;
            overflow-y: scroll;
            outline: none;
            border-radius: 4px;
            min-height: 28px;
            color: $medium-font-color;
        }

        .sendButton {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            position: absolute;
            right: 0;
            background-color: #0dc648;

            &:disabled {
                background-color: #ccc;
            }
        }
    }

    .commentList {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 150px;
        overflow-y: scroll;

        .comment {
            display: flex;
            flex-direction: column;

            p {
                font-size: 13px;
                font-weight: 400;
                color: $medium-font-color;
            }
            span {
                font-size: 11px;
                font-weight: 300;
                color: #909c9d;
            }
        }
    }
}
