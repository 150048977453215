@import 'src/variables';

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    max-width: 320px;

    h6 {
        font-size: 15px;
    }

    span {
        font-size: 13px;
    }

    select {
        width: 100%;
        height: 30px;
        border-radius: 4px;
        border: 1px solid $light-border-color;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 12px;

        button {
            height: 34px;
            padding: 0 12px;
            border-radius: 4px;
            font-weight: 500;
        }

        .confirm {
            background-color: #0dc648;
            color: white;
        }

        .discard {
            background: none;
            color: $soft-red;
            transition: background-color 0.18s;

            &:hover {
                background-color: #ffe5e5;
            }
        }
    }
}
