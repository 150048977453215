@import 'src/variables';

.container {
    position: relative;
    flex-direction: column;
    display: flex;
    gap: 10px;

    .wall {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }

    input {
        border: 1px solid #d8dee7;
        border-radius: 4px;
        padding: 0 10px;
        color: #414552;
        outline: none;
        font-size: 14px;
        width: 100%;
    }

    .list {
        position: absolute;
        list-style: none;
        width: 100%;
        border: 1px solid #d8dee7;
        border-top: none;
        border-radius: 0 0 6px 6px;
        overflow: hidden;
        max-height: 240px;
        overflow-y: scroll;
        z-index: 99;

        li {
            background-color: white;
            padding: 8px 10px;
            display: flex;
            flex-direction: column;
            font-size: 13px;
            color: #414552;

            &:hover {
                cursor: pointer;
                background-color: #f3f7ff;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #d8dee7;
            }
        }
    }

    .pillsContainer {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .tagPill {
        display: flex;
        align-items: center;
        background-color: #e4e6f5;
        padding: 0 8px;
        height: 22px;
        width: fit-content;
        border-radius: 11px;
        gap: 4px;

        span {
            font-size: 12px;
            color: $dark-font-color;
        }

        .deletePill {
            cursor: pointer;
        }
    }
}
