@import 'src/variables';

.formSection {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    margin-bottom: 18px;
    gap: 10px;
    border-left: 2px dashed $disabled-color;
    padding-left: 14px;

    .userDetails {
        display: flex;
        flex-direction: column;
        padding: 8px;
        background-color: #d0eee5;
        border: 1px solid #5ccdad;
        border-radius: 6px;
        color: $dark-font-color;
        gap: 4px;

        .spaceBetween {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .closeIcon:hover {
            cursor: pointer;
            stroke: #fa6c6c;
        }
    }

    .createUserLink {
        font-size: 12px;
        color: #635bff;
        &:hover {
            cursor: pointer;
        }
    }
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
        height: 34px;
    }

    label {
        font-size: 14px;
        font-weight: 400;
    }
    .column {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .row {
        display: flex;
        gap: 20px;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 12px;

        button {
            height: 34px;
            padding: 0 12px;
            border-radius: 4px;
            gap: 8px;
            font-weight: 500;
        }

        .confirm {
            background-color: #0dc648;
            color: white;
        }

        .discard {
            background: none;
            color: $soft-red;
            transition: background-color 0.18s;

            &:hover {
                background-color: #ffe5e5;
            }
        }
    }

    .errorMessage {
        color: #fa6c6c;
        font-size: 13px;
    }
}
