@import 'src/variables';

.modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    width: 245px;
    border-radius: 8px;
    padding: 0 8px;
    opacity: 0;
    box-shadow: 0 0 0 1px #8898aa1a, 0 15px 35px #31315d1a, 0 5px 15px #00000014;
    z-index: 99;
    transform: scale(0);
    transition: all 0.25s;
    top: 32px;

    &.shown {
        transform: scale(1);
        opacity: 1;
    }

    label {
        color: $dark-font-color;
        font-size: 13px;
        padding: 8px 0;
    }

    input {
        height: 28px;
        font-size: 13px;
        margin-bottom: 8px;
    }

    ul {
        display: flex;
        flex-direction: column;
        list-style: none;

        li {
            font-size: 13px;
            display: flex;
            align-items: center;
            height: 34px;

            &:hover {
                cursor: pointer;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #e9eef5;
            }
        }
    }
}
