@import 'src/variables';

.content {
    display: flex;
    justify-content: right;
    height: 100%;
    padding: 10px;
    background-color: #cbd2da;
    background-image: url('./city.png');
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;

    form {
        background-color: white;
        height: 100%;
        border-radius: 10px;
        padding: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.4);

        h1 {
            font-size: 16px;
            text-transform: uppercase;
            color: $dark-font-color;
            letter-spacing: 1.5px;
        }

        .image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 2px solid #000;
            margin-top: 60px;
            margin-bottom: 60px;
            background-image: url('./officer.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-color: #e1ffe1;
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 260px;
        }

        input {
            width: 100%;
            height: 40px;
            border: 2px solid #ddd;
            padding: 0 10px;
            outline: none;

            &:focus {
                border: 2px solid #000;
            }
        }

        .error {
            color: $soft-red;
            font-size: 13px;
            width: 100%;
            text-align: left;
        }

        button {
            text-transform: uppercase;
            font-size: 12px;
            color: white;
            background-color: #635bff;
            height: 40px;
            display: flex;
            align-items: center;
            font-weight: bold;
            letter-spacing: 1.5px;
            margin-top: 10px;
            border-radius: 6px;
            padding: 0 16px;

            &:hover {
                cursor: pointer;
            }

            &:disabled {
                background-color: #ddd;
                &:hover {
                    cursor: auto;
                }
            }
        }
    }
    .enterIcon {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,0.1);
        border: 1px solid rgba(255,255,255,0.1);
        border-radius: 3px;
        padding-top: 2px;
    }
}
