@import 'src/variables';

.checkbox {
    display: flex;
    gap: 10px;
    align-items: center;
    flex: 1;

    input {
        width: 14px;
        height: 14px;

        &:hover {
            cursor: pointer;
        }
    }

    label {
        flex: 1;
        font-size: 13px;
        color: $dark-font-color;

        &:hover {
            cursor: pointer;
        }
    }
}
