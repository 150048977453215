@import 'src/variables';

.card {
    background-color: white;
    padding: 14px;
    border-radius: 8px;
    display: flex;
    width: 260px;
    height: calc(100% - 28px);
    position: absolute;
    top: 14px;
    left: -20px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    overflow-y: scroll;
    transition: visibility 0.1s, opacity 0.25s, left 0.25s;

    &.shown {
        visibility: visible;
        opacity: 1;
        left: 14px;
        transition: visibility 0.4s, opacity 0.25s, left 0.25s;
    }

    .cardContainer {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    .backButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px;
        background-color: #eee;
        border-radius: 6px;
        &:hover {
            cursor: pointer;
        }
    }

    .title {
        font-size: 15px;
        font-weight: 600;
        color: #363b3b;
    }

    span {
        font-size: 13px;
        font-weight: 400;
        color: #909c9d;
    }

    .subtitle {
        font-size: 13px;
        font-weight: 600;
        color: #363b3b;
    }

    .link {
        text-decoration: underline;
        font-size: 13px;
        color: #909c9d;

        &:hover {
            cursor: pointer;
        }
    }

    .tip {
        font-size: 12px;
        font-weight: 500;
        color: #999;
    }

    .planName {
        font-size: 15px;
        font-weight: 600;
        color: $soft-red;

        &.active {
            color: #0dc648;
        }
    }

    .cancelledText {
        color: $soft-red;
        font-size: 14px;
        display: flex;
        height: 30px;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .finishedText {
        color: $soft-green;
        font-size: 14px;
        display: flex;
        height: 30px;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .changeStatusButton {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: #0dc648;
        color: #fff;
        font-weight: 500;
        &:disabled {
            background-color: #999;
            color: #fff;
        }
    }

    .discardButton {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: #f5fae5;
        color: #08752b;
        font-weight: 500;
    }

    footer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;
        gap: 12px;
    }

    .cancelPetitionButton {
        height: 34px;
        padding: 0 12px;
        border-radius: 4px;
        font-weight: 500;
        background: none;
        color: $soft-red;
        transition: background-color 0.18s;

        &:hover {
            background-color: #ffe5e5;
        }
    }
}
