@import 'src/variables';

span {
    font-size: 13px;
    color: $disabled-color;
}

section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.form {
    display: flex;
    flex-direction: column;
    max-width: 400px;

    label {
        font-size: 16px;
        font-weight: bold;
        color: $dark-font-color;
    }

    .col {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: 10px;
    }

    .formSection {
        display: flex;
        flex-direction: column;
        margin-top: 6px;
        margin-bottom: 18px;
        gap: 10px;
        border-left: 2px dashed $disabled-color;
        padding-left: 14px;
    }
}
