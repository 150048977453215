.baseButton {
    display: flex;
    align-items: center;
    height: 40px;
    width: fit-content;
    gap: 5px;
    padding: 0 10px;
    outline: none;
    border: none;

    &:hover {
        cursor: pointer;
    }

    &.primary {
        background-color: #635bff;
        span {
            color: white;
        }
    }

    &.secondary {
        border: none;
        background-color: #e5e3fb;

        span {
            color: #635bff;
        }
    }

    &:disabled {
        background-color: #ddd;
        &:hover {
            cursor: auto;
        }
    }

    span {
        flex: 1;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
    }
}

.iconContainer {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
