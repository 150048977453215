@import 'src/variables';

.formSection {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    margin-bottom: 18px;
    gap: 10px;
    border-left: 2px dashed $disabled-color;
    padding-left: 14px;

    .modal {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 600px;

        p {
            font-size: 14px;
            color: $dark-font-color;
        }
    }

    .mapContainer {
        position: relative;
        width: 600px;
        height: 400px;
        overflow: hidden;
        border-radius: 4px;
    }

    .mapIconContainer {
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .chooseLocationButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: 1px solid $lavanda;
        color: $lavanda;
        font-size: 13px;
        height: 34px;
        padding: 0 10px;
        border-radius: 4px;
        transition: background-color 0.25s;

        &:hover {
            background-color: $lavanda;
            color: white;
        }
    }
}
