@import 'src/variables';

.wrapper {
    position: relative;

    .searchUserInput {
        border: 1px solid $light-border-color;
        height: 40px;
        width: 100%;
        outline: none;
        padding: 0 10px;
        border-radius: 6px;
        color: $dark-font-color;

        &.activeSearch {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .resultList {
        position: absolute;
        list-style: none;
        width: 100%;
        top: 40px;
        border: 1px solid $light-border-color;
        border-top: none;
        border-radius: 0 0 6px 6px;
        overflow: hidden;
        max-height: 240px;
        overflow-y: scroll;
        z-index: 99;

        li {
            background-color: white;
            padding: 14px;
            display: flex;
            flex-direction: column;

            &:hover {
                cursor: pointer;
                background-color: #f3f7ff;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $light-border-color;
            }

            .name {
                color: $dark-font-color;
                font-size: 14px;
                font-weight: 500;
            }

            .email {
                color: $disabled-color;
                font-size: 12px;
            }
        }
    }
}
