@import 'src/variables';

.mapMarker {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
}

.noEmergencies {
    background-color: white;
    box-shadow: $box-shadow-with-border-hover;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    position: absolute;
    top: 20px;
    padding: 0 10px;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
    color: $dark-font-color;
}

.centeringButton {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.2);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}
