@import 'src/variables';

.formSection {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    margin-bottom: 18px;
    gap: 10px;
    border-left: 2px dashed $disabled-color;
    padding-left: 14px;

    .blockLayer {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .selector {
        position: relative;
    }

    .list {
        position: absolute;
        background: white;
        max-height: 150px;
        width: 100%;
        overflow: scroll;
        border: 1px solid $light-border-color;
        top: 39px;

        &:hover {
            cursor: pointer;
        }

        li {
            display: flex;
            gap: 12px;
            padding: 8px;
            font-size: 14px;
            align-items: center;

            &:not(:last-child) {
                border-bottom: 1px solid $light-border-color;
            }

            span {
                font-size: 13px;
                color: $medium-font-color;
                user-select: none;
            }

            .checkbox {
                width: 14px;
                height: 14px;
                border-radius: 2px;
                border: 1px solid $light-border-color;

                &.selected {
                    border: none;
                    background: $lavanda;
                }
            }
        }
    }
}
