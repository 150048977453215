.key {
    background: linear-gradient(-225deg, #d5dbe4, #f8f8f8);
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    box-shadow: inset 0 -2px 0 0 #cdcde6, inset 0 0 1px 1px #fff,
        0 1px 2px 1px rgba(30, 35, 90, 0.4);
    color: #969faf;
    min-width: 20px;
    padding: 0 8px;
    font-size: 13px;
    height: 24px;
}
