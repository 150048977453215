@import 'src/variables';

.goBack {
    margin-bottom: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 20px;
    cursor: pointer;

    &.light {
        border-bottom: 1px solid $medium-font-color;

        span {
            color: $medium-font-color;
        }
    }

    &.dark {
        border-bottom: 1px solid white;

        span {
            color: white;
        }
    }
}
