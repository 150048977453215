@import 'src/variables';

.container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 99;

    &.shown {
        visibility: visible;
        opacity: 1;
    }
}

.fade {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal {
    padding: 14px;
    background-color: white;
    border-radius: 6px;
    box-shadow: $box-shadow-with-border;
    z-index: 1;
}
