.searchInput {
    display: flex;
    height: 32px;
    border: 1px solid #d8dee7;
    border-radius: 6px;
    background-color: white;
    align-items: center;
    position: relative;

    .searchIconContainer {
        width: 32px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #d8dee7;
        border-radius: 6px 0 0 6px;
    }

    input {
        height: 100%;
        flex: 1;
        border: none;
        font-size: 13px;
    }

    .closeContainer {
        position: absolute;
        right: 6px;
        top: 6px;

        &:hover {
            cursor: pointer;
        }
    }
}
