@import 'src/variables';

#appMenu {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    transition: all 0.4s;
    position: relative;
    z-index: 98;
    justify-content: space-between;

    &.light {
        background-color: $light-component-background;
    }

    &.dark {
        background-color: $dark-component-background;
        li {
            color: white;
        }
        .collapseButton {
            background-color: $dark-component-background;
        }
    }

    ul {
        padding: 14px 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
            display: flex;
            align-items: center;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            gap: 6px;
            padding: 0 14px;
            user-select: none;
            color: $dark-font-color;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .logoutButton {
        display: flex;
        height: 40px;
        align-items: center;
        padding: 0 14px;
        font-size: 14px;
        color: $dark-font-color;

        &:hover {
            cursor: pointer;
        }
    }

    .collapseButton {
        background-color: white;
        position: absolute;
        outline: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
        bottom: 80px;
        right: -20px;
        z-index: 1;
    }

    .version {
        position: relative;
        bottom: 0;
        padding: 10px 0;
        margin: 0 14px;
        border-top: 1px solid #d8dee7;
    }
}
