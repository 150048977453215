@import 'src/variables';

section.container {
    display: flex;
    gap: 10px;
}

.selectedList {
    display: flex;
    gap: 10px;
    list-style: none;
    overflow-x: scroll;
}

.card {
    padding: 8px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.03);
    height: 100px;
    width: 90px;
    min-width: 90px;

    .imageContainer {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d6f8d3;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
    }

    .nameContainer {
        text-align: center;
        line-height: 15px;
        user-select: none;
    }

    .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e5eaea;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        &:hover {
            cursor: pointer;
        }
    }
}

.selection {
    position: relative;

    input {
        height: 40px;
        border-radius: 4px;

        &.focused {
            border-radius: 4px 4px 0 0;
        }
    }

    .listOverlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
    }

    .list {
        position: absolute;
        max-height: 120px;
        display: flex;
        flex-direction: column;
        list-style: none;
        background-color: white;
        border: 1px solid #d8dee7;
        border-radius: 0 0 4px 4px;
        overflow: hidden;
        overflow-y: scroll;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);

        li {
            display: flex;
            min-height: 36px;
            height: 36px;
            padding: 0 10px;
            align-items: center;
            &:not(:last-child) {
                border-bottom: 1px solid #ddd;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}
