@import 'src/variables';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 18px;

  h5 {
    color: $dark-font-color;
  }

  h6 {
    font-size: 13px;
    color: $medium-font-color;
  }
}
