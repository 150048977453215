@import 'src/variables';

.container {
    position: relative;
    display: flex;
    align-items: center;
    height: 28px;
    gap: 6px;
    padding: 0 8px;
    background-color: white;
    border-radius: 6px;
    box-shadow: $box-shadow-with-border;
    transition: 0.2s box-shadow;

    &:hover {
        box-shadow: $box-shadow-with-border-hover;
        cursor: pointer;
    }

    span {
        font-weight: 500;
        color: #969ea6;
    }

    select {
        height: 28px;
        font-weight: 500;
        color: #969ea6;
        border: none;
        outline: none;
        appearance: none;
        padding: 0;
        margin: 0;
        user-select: none;
        opacity: 0;
        position: absolute;
        width: 100%;

        &:hover {
            cursor: pointer;
        }
    }

    .dropdownContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 100%;
    }
}
