@import 'src/variables';

.form {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .row {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 1rem 0;
        align-items: center;
        border-top: 1px solid #e3e4e8;
    }

    label {
        font-size: 0.875rem;
        color: $medium-font-color;
    }

    input {
        width: 100%;
        height: 34px;
        border: 2px solid #ddd;
        padding: 0 10px;
        outline: none;

        &:focus {
            border: 2px solid #000;
        }
    }

    select {
        height: 34px;
        border: 2px solid #ddd;
        padding: 0 10px;
        outline: none;
        width: 80px;

        &:focus {
            border: 2px solid #000;
        }
    }

    .errorMessage {
        font-size: 0.875rem;
        color: $soft-red;
    }
}
