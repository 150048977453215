@import 'src/variables';

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Medium.ttf);
    font-weight: 500;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
}

.view {
    width: 100%;
    min-height: 100vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: background-color 0.4s;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #fbfbfd;

    .view-title {
        font-size: 24px;
        color: $dark-font-color;
    }

    &.light {
        background-color: $light-background;
    }

    &.dark {
        background-color: $dark-background;

        .view-title {
            color: white;
        }
    }

    .viewContent {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
    }
}

.flex {
    display: flex;
}

.row {
    display: flex;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
}

.space-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

button {
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
}

input,
select {
    border: 1px solid $light-border-color;
    height: 40px;
    width: 100%;
    outline: none;
    padding: 0 10px;
    border-radius: 6px;
    color: $dark-font-color;
}

/**
This is to remove the up-down arrows on inputs of type: number
 */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
