@import '../../variables';

.deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: none;
    padding: 4px;

    &:hover {
        svg path {
            fill: #fa6c6c;
        }
    }
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 320px;

    span {
        font-size: 0.875rem;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 12px;

        button {
            height: 34px;
            padding: 0 12px;
            border-radius: 4px;
            font-weight: 500;
        }

        .confirm {
            background-color: #0dc648;
            color: white;

            &:disabled {
                background: #ccc;
            }
        }

        .discard {
            background: none;
            color: $soft-red;
            transition: background-color 0.18s;

            &:hover {
                background-color: #ffe5e5;
            }
        }
    }
}
