@import 'src/variables';

.metricCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: white;
  flex: 1;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
  }

  h5 {
    font-size: 14px;
    color: $medium-font-color;
  }

  b {
    font-size: 32px;
    color: $dark-font-color;
  }
}
