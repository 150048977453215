.link {
    text-decoration: underline;
    font-size: 13px;
    color: #909c9d;
    display: flex;
    align-items: center;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }

    img {
        width: 18px;
    }
}

.copied {
    font-size: 13px;
    color: #909c9d;
}
