.container {
    display: flex;
    width: 100%;
    position: relative;

    .loader {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    input {
        border: 1px solid #d8dee7;
        border-radius: 4px;
        height: 40px;
        padding: 0 10px;
        color: #414552;
        flex: 1;
        outline: none;
        font-size: 14px;

        &.activeSearch {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .list {
        position: absolute;
        list-style: none;
        width: 100%;
        top: 40px;
        border: 1px solid #d8dee7;
        border-top: none;
        border-radius: 0 0 6px 6px;
        overflow: hidden;
        max-height: 240px;
        overflow-y: scroll;
        z-index: 99;

        li {
            background-color: white;
            padding: 8px 10px;
            display: flex;
            flex-direction: column;
            font-size: 13px;
            color: #414552;

            &:hover {
                cursor: pointer;
                background-color: #f3f7ff;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #d8dee7;
            }
        }
    }
}
