@import 'src/variables';

.title {
    font-size: 20px;
    color: $dark-font-color;
}

h4 {
    color: #414552;
}

section.content {
    display: flex;
    gap: 40px;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        border: 1px solid #e7e7ef;
    }
}

ul {
    list-style: none;
    height: fit-content;
}

.infoSection {
    display: flex;
    flex-direction: column;
    background-color: #edf3f5;
    padding: 8px;
    border-radius: 6px;
    font-size: 14px;
    color: $dark-font-color;
    margin-bottom: 20px;

    h5 {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    span {
        font-size: 14px;
    }
}

.commentList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}
.comment {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-left: 3px solid #969ea6;
    padding-left: 6px;

    .content {
        color: $dark-font-color;
        font-size: 15px;
    }

    .commentMetadata {
        font-size: 12px;
    }
}
