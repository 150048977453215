.descriptionTable {
    padding: 0 2rem;
    .row {
        border-top: 1px solid #f3f4f6;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 1.5rem 0;
        font-size: 0.875rem;

        dt {
            font-weight: 500;
            color: #414552;
        }

        dd {
            color: #374151ff;
        }
    }
}
