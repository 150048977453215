@import 'src/variables';

.title {
    font-size: 20px;
    color: $dark-font-color;
}

section {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .toggles {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        gap: 14px;
    }

    .row {
        display: flex;
        gap: 10px;
    }

    .col {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .shortcutRow {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 30px;
    }

    h5 {
        color: $dark-font-color;
    }

    .themes {
        display: flex;
        gap: 40px;

        .select {
            display: flex;
            gap: 10px;
            font-size: 14px;
            align-items: center;

            label {
                user-select: none;
            }

            input {
                width: 16px;
                height: 16px;
            }
        }

        .imageContainer {
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            border: 2px solid transparent;

            &.selected {
                border-color: #635bff;
            }
        }
    }
}
