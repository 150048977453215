@import 'src/variables';

.title {
    font-size: 20px;
    color: $dark-font-color;
}

.subscriptionPill {
    width: fit-content;
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px;
}

.subInfo {
    color: $dark-font-color;
}

section.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.infoSection {
    display: flex;
    flex-direction: column;
    color: $dark-font-color;

    h5 {
        font-size: 16px;
        border-bottom: 1px solid #ddd;
    }
}

.infoRow {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    font-size: 14px;
}

.noVehiclesText {
    color: #999;
    font-size: 14px;
    margin-top: 10px;
}

.vehicleList {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 10px;

    li {
        display: flex;
        justify-content: center;
        flex-direction: column;

        b {
            font-size: 13px;
        }
    }
}
