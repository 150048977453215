@import 'src/variables';

.statusPath {
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: space-between;
    gap: 4px;

    .step {
        display: flex;
        gap: 10px;

        .draws {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
        }

        .circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ddd;
            margin-top: 5px;

            &.active {
                background-color: #28c840;
            }
        }

        .name {
            font-size: 14px;
            color: $dark-font-color;
            font-weight: 500;
        }

        .line {
            width: 2px;
            background-color: #ddd;
            height: 60px;
        }

        .info {
            display: flex;
            flex-direction: column;

            .transitionTime {
                font-size: 13px;
                color: #666;
                margin-top: 12px;
            }
        }
    }
}
